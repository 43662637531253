import { getTenant } from "../../api/TenantUtils";
import { KeycloakApi } from "../../api/KeycloakApi";
import { isPublicPath, UrlPathLogin } from "../../api/url";
import { BooleanThunk, StringThunk } from "../IStore";
import { AccessRole } from "../models/user/AccessRole";
import { LoginDto, TokenDto } from "../models/user/LoginDto";
import { thunkCreateErrorNotification } from "./NotificationActions";

export const LOGIN_STATE = "LOGIN_STATE";

export const thunkLogin =
  (loginDto: LoginDto): BooleanThunk =>
  async (dispatch) => {
    try {
      const token = await KeycloakApi.login(loginDto);
      setToken(token);
      dispatch({
        type: LOGIN_STATE,
        payload: true,
      });

      const redirectPath = localStorage.getItem("redirectAfterLogin");
      if (redirectPath) {
        localStorage.removeItem("redirectAfterLogin");
        window.location.href = redirectPath;
      } else {
        window.location.href = "/";
      }

      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Fehler beim Login", e));
      return false;
    }
  };

export const thunkPublicUserLogin = (): StringThunk => async (dispatch) => {
  const tenant = getTenant().toUpperCase();
  const LANDING_PAGE_PUBLIC_USER_USERNAME =
    window.env[`REACT_APP_${tenant}_PUBLIC_USER`] || process.env[`REACT_APP_${tenant}_PUBLIC_USER`];
  const LANDING_PAGE_PUBLIC_USER_PASSWORD =
    window.env[`REACT_APP_${tenant}_PUBLIC_PASSWORD`] || process.env[`REACT_APP_${tenant}_PUBLIC_PASSWORD`];

  try {
    const loginDto = {
      username: LANDING_PAGE_PUBLIC_USER_USERNAME ?? "",
      password: LANDING_PAGE_PUBLIC_USER_PASSWORD ?? "",
    };
    const rawToken = await KeycloakApi.publicLogin(loginDto);
    const keycloakToken = JSON.parse(rawToken) as KeycloakToken;

    return keycloakToken.access_token;
  } catch (e) {
    dispatch(thunkCreateErrorNotification("Fehler beim Login", e));
    return "";
  }
};

export function ensureToken(): TokenDto | undefined {
  const token = getValidToken();
  if (!token) {
    logout();
    return;
  }
  return token;
}

function setToken(token: string) {
  localStorage.setItem("token", token);
  localStorage.setItem("token_timestamp", String(Date.now()));
}

export function logout() {
  deleteToken();
  if (!isPublicPath()) {
    const currentPath = window.location.pathname + window.location.search;
    localStorage.setItem("redirectAfterLogin", currentPath);
    window.location.href = UrlPathLogin;
  }
}

export function deleteToken() {
  localStorage.removeItem("token");
  localStorage.removeItem("token_timestamp");
}

export function isLoggedIn(): boolean {
  const token = getValidToken();
  return token !== undefined;
}

function getValidToken(): TokenDto | undefined {
  const rawToken = localStorage.getItem("token");
  if (!rawToken) return; // no token found in local storage
  try {
    const token = JSON.parse(rawToken) as KeycloakToken;
    if (!token) return; // token from local storage could not be parsed
    if (!token.access_token) return; // token from local storage doesn't contain an access_token
    const jwt = parseJwt(token.access_token);
    if (!jwt) return; // access_token could not be parsed as an JWT
    const tokenAge = getTokenAge(token);
    if (tokenAge === undefined) return; // token age can be 0
    registerRefreshToken(token, tokenAge);
    return {
      access_token: token.access_token,
      customer_number: jwt.customer_number,
      groups: jwt.groups,
    };
  } catch {
    // no token is returned
  }
}

let isRefreshTokenRegistered = false;

function registerRefreshToken(token: KeycloakToken, tokenAge: number) {
  if (isRefreshTokenRegistered) return;
  const refreshTokenFunc = async () => {
    try {
      const rawRefreshToken = await KeycloakApi.refresh(token.refresh_token);

      setToken(rawRefreshToken);
      // retrigger token refresh
      isRefreshTokenRegistered = false;
      getValidToken();
    } catch (e) {
      if (e === "Session not active") {
        // I had a situation where a refresh token request was denied although the refresh_expires_in value was okay
        logout();
      }
    }
  };

  const timeRemaining = token.expires_in - tokenAge;
  setTimeout(refreshTokenFunc, timeRemaining * 1000);
  isRefreshTokenRegistered = true;
}

function parseJwt(accessToken: string): LoyaltyToken {
  return JSON.parse(atob(accessToken.split(".")[1]));
}

interface KeycloakToken {
  access_token: string;
  expires_in: number;
  "not-before-policy": number;
  refresh_expires_in: number;
  refresh_token: string;
  scope: string;
  session_state: string;
  token_type: string;
}

interface LoyaltyToken {
  acr: string;
  "allowed-origins": string[];
  azp: string;
  customer_number: string;
  email: string;
  exp: number;
  groups: AccessRole[];
  iat: number;
  iss: string;
  jti: string;
  preferred_username: string;
  scope: string;
  session_state: string;
  sub: string;
  typ: string;
  upn: string;
}

export async function tokenRequestOptions(method: string, useToken: boolean = true) {
  const requestOptions: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (useToken) {
    const token = ensureToken();
    if (!token) return;
    requestOptions.headers = {
      ...requestOptions.headers,
      Authorization: "Bearer " + token.access_token,
    };
  }
  return requestOptions;
}

export async function tokenRequestOptionsOneTime(method: string, accessToken: string) {
  const requestOptions: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
  };
  requestOptions.headers = {
    ...requestOptions.headers,
    Authorization: "Bearer " + accessToken,
  };
  return requestOptions;
}

export async function tokenRequestOptionsForMultipart(method: string, useToken: boolean = true) {
  const requestOptions: RequestInit = {
    method,
  };
  if (useToken) {
    const token = ensureToken();
    if (!token) return;
    requestOptions.headers = {
      ...requestOptions.headers,
      Authorization: "Bearer " + token.access_token,
    };
  }
  return requestOptions;
}

export async function parseResponse(response: Response) {
  if (response.status === 401) {
    logout();
    return;
  }
  const text = await response.text();
  let data;
  try {
    data = JSON.parse(text);
  } catch {
    if (response.status !== 200 && response.status !== 201 && response.status !== 204) throw response.statusText;
  }
  if (data && data.error) {
    throw data.error;
  }
  return data;
}

function getTokenAge(token: KeycloakToken): number | undefined {
  const tokenTimestamp = localStorage.getItem("token_timestamp");
  if (!tokenTimestamp) return; // missing timestamp for token
  const tokenAge = Math.trunc((Date.now() - Number(tokenTimestamp)) / 1000);
  if (tokenAge > token.refresh_expires_in) return; // token has already expired and can't be refreshed
  return tokenAge;
}

export function hasRole(role: AccessRole) {
  const loyaltyToken = ensureToken();
  return loyaltyToken?.groups?.includes(role) || false;
}

export function canReadDashboard() {
  return hasRole(AccessRole.DASHBOARD_READONLY);
}

export function canMaintainAdminPassword() {
  return hasRole(AccessRole.ADMIN_PASSWORD_MAINTAIN);
}

export function canReadCampaigns() {
  if (getTenant().toLowerCase().includes("engelhorn")) {
    return false;
  }
  return hasRole(AccessRole.CAMPAIGN_READONLY);
}

export function canMaintainCampaigns() {
  return hasRole(AccessRole.CAMPAIGN_MAINTAIN);
}

export function canReadCashBack() {
  if (getTenant().toLowerCase().includes("engelhorn")) {
    return false;
  }
  return hasRole(AccessRole.CASHBACK_CONFIG_READONLY);
}

export function canMaintainCashBack() {
  return hasRole(AccessRole.CASHBACK_CONFIG_MAINTAIN);
}

export function canReadBonus() {
  return hasRole(AccessRole.BONUS_READONLY);
}

export function canReadAppContent() {
  return hasRole(AccessRole.APP_CONTENT_READONLY);
}

export function canMaintainAppContent() {
  return hasRole(AccessRole.APP_CONTENT_MAINTAIN);
}

export function canMaintainBonus() {
  return hasRole(AccessRole.BONUS_MAINTAIN);
}

export function canReadBonusPremiums() {
  if (getTenant().toLowerCase().includes("engelhorn")) {
    return false;
  }
  return hasRole(AccessRole.PREMIUM_READONLY);
}

export function canMaintainBonusPremiums() {
  return hasRole(AccessRole.PREMIUM_MAINTAIN);
}

export function canReadUsers() {
  return hasRole(AccessRole.ADMIN_USER_READONLY);
}

export function canMaintainUsers() {
  return hasRole(AccessRole.ADMIN_USER_MAINTAIN);
}

export function canReadPreferences() {
  if (getTenant().toLowerCase().includes("engelhorn")) {
    return false;
  }
  return hasRole(AccessRole.PREFERENCE_READONLY);
}

export function canMaintainPreferences() {
  return hasRole(AccessRole.PREFERENCE_MAINTAIN);
}

export function canReadCustomerPreferences() {
  return hasRole(AccessRole.CUSTOMER_PREFERENCE_READONLY);
}

export function canMaintainCustomerPreferences() {
  return hasRole(AccessRole.CUSTOMER_PREFERENCE_MAINTAIN);
}

export function canReadOperationalUnits() {
  return hasRole(AccessRole.UNIT_READONLY);
}

export function canMaintainOperationalUnits() {
  return hasRole(AccessRole.UNIT_MAINTAIN);
}

export function canReadCustomers() {
  return hasRole(AccessRole.CUSTOMER_READONLY);
}

export function canMaintainCustomers() {
  return hasRole(AccessRole.CUSTOMER_MAINTAIN);
}

export function canReadCircles() {
  return hasRole(AccessRole.CIRCLE_READONLY);
}

export function canMaintainCircles() {
  return hasRole(AccessRole.CIRCLE_MAINTAIN);
}

export function canReadIdentifications() {
  return hasRole(AccessRole.IDENTITY_READONLY);
}

export function canMaintainIdentifications() {
  return hasRole(AccessRole.IDENTITY_MAINTAIN);
}

export function canReadIdentificationTypes() {
  return hasRole(AccessRole.IDENTITY_TYPE_READONLY);
}

export function canMaintainIdentificationTypes() {
  return hasRole(AccessRole.IDENTITY_TYPE_MAINTAIN);
}

export function canReadFamilies() {
  return hasRole(AccessRole.FAMILY_READONLY);
}

export function canMaintainFamilies() {
  return hasRole(AccessRole.FAMILY_MAINTAIN);
}

export function canReadConfig() {
  return hasRole(AccessRole.CONFIG_READONLY);
}

export function canMaintainConfig() {
  return hasRole(AccessRole.CONFIG_MAINTAIN);
}

export function canReadFlows() {
  return hasRole(AccessRole.FLOW_READONLY);
}

export function canMaintainFlows() {
  return hasRole(AccessRole.FLOW_MAINTAIN);
}

export function canReadGdpr() {
  return hasRole(AccessRole.GDPR_READONLY);
}

export function canMaintainGdpr() {
  return hasRole(AccessRole.GDPR_MAINTAIN);
}

export function canReadCustomerInteractions() {
  return hasRole(AccessRole.CUSTOMER_INTERACTION_READONLY);
}

export function canMaintainCustomerInteractions() {
  return hasRole(AccessRole.CUSTOMER_INTERACTION_MAINTAIN);
}

export function canReadSuperUser() {
  return hasRole(AccessRole.SUPERUSER_READONLY);
}

export function canMaintainSuperUser() {
  return hasRole(AccessRole.SUPERUSER_MAINTAIN);
}

export function canReadPropertyGroup() {
  return hasRole(AccessRole.PROPERTYGROUP_READONLY);
}

export function canMaintainPropertyGroup() {
  return hasRole(AccessRole.PROPERTYGROUP_MAINTAIN);
}

export function canReadReports() {
  if (getTenant().toLowerCase().includes("engelhorn")) {
    return false;
  }
  return hasRole(AccessRole.REPORT_READONLY);
}

export function setTestContextToken() {
  const loyaltyToken: Partial<LoyaltyToken> = {
    groups: [
      AccessRole.DASHBOARD_READONLY,
      AccessRole.ADMIN_PASSWORD_MAINTAIN,
      AccessRole.BONUS_READONLY,
      AccessRole.BONUS_MAINTAIN,
      AccessRole.CAMPAIGN_READONLY,
      AccessRole.CAMPAIGN_MAINTAIN,
      AccessRole.CASHBACK_CONFIG_READONLY,
      AccessRole.CASHBACK_CONFIG_MAINTAIN,
      AccessRole.CIRCLE_READONLY,
      AccessRole.CIRCLE_MAINTAIN,
      AccessRole.CONFIG_READONLY,
      AccessRole.CONFIG_MAINTAIN,
      AccessRole.IDENTITY_READONLY,
      AccessRole.IDENTITY_MAINTAIN,
      AccessRole.IDENTITY_TYPE_READONLY,
      AccessRole.IDENTITY_TYPE_MAINTAIN,
      AccessRole.FLOW_READONLY,
      AccessRole.FLOW_MAINTAIN,
      AccessRole.CUSTOMER_READONLY,
      AccessRole.CUSTOMER_MAINTAIN,
      AccessRole.CUSTOMER_PREFERENCE_READONLY,
      AccessRole.CUSTOMER_PREFERENCE_MAINTAIN,
      AccessRole.CUSTOMER_INTERACTION_READONLY,
      AccessRole.CUSTOMER_INTERACTION_MAINTAIN,
      AccessRole.PREFERENCE_READONLY,
      AccessRole.PREFERENCE_MAINTAIN,
      AccessRole.PREMIUM_READONLY,
      AccessRole.PREMIUM_MAINTAIN,
      AccessRole.UNIT_READONLY,
      AccessRole.UNIT_MAINTAIN,
      AccessRole.GDPR_READONLY,
      AccessRole.GDPR_MAINTAIN,
      AccessRole.SUPERUSER_READONLY,
      AccessRole.SUPERUSER_MAINTAIN,
      AccessRole.PROPERTYGROUP_READONLY,
      AccessRole.PROPERTYGROUP_MAINTAIN,
      AccessRole.REPORT_READONLY,
      AccessRole.APP_CONTENT_READONLY,
      AccessRole.APP_CONTENT_MAINTAIN,
    ],
  };

  const keycloakToken: Partial<KeycloakToken> = {
    access_token: "test." + btoa(JSON.stringify(loyaltyToken)),
    expires_in: 1800,
  };
  setToken(JSON.stringify(keycloakToken));
}
